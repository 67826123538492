import { graphql } from 'gatsby'
import React from 'react'
import type { HeadProps } from 'gatsby'
import SEO from '~/components/seo'
import Layout from '~/components/layout'
import ArticleCard from '~/components/article-card'
import { ContentfulTagArticleTemplateQuery } from '/types/graphql-types'
import { css } from '@emotion/react'
import { ARTICLE_STYLE, PAGE_HEADING_STYLE } from '~/styles/common'

export const pageQuery = graphql`
  query ContentfulTagArticleTemplate($slug: String!) {
    contentfulTag(slug: { eq: $slug }) {
      title
      slug
      article {
        title
        slug
        description
        featureImage {
          secure_url
          height
          width
        }
        createdAt
        publishedAt(formatString: "YYYY年M月D日")
        tags {
          title
        }
      }
    }
  }
`

const getTagTitle = (title: string) => {
  return `#${title} の記事一覧`
}

interface Props {
  data: ContentfulTagArticleTemplateQuery
}

const ContentfulTagArticle: React.FC<Props> = ({ data }) => {
  const { title, article } = data.contentfulTag!
  const articlesRaw = article!
  const articles = articlesRaw.sort((a, b) => {
    return a!.createdAt! > b!.createdAt! ? -1 : 1
  })

  return (
    <Layout>
      <h2 css={style.heading}>{getTagTitle(title!)}</h2>
      <div css={style.wrapper}>
        {articles.map((node, idx) => {
          const { title, slug, description, featureImage, publishedAt, tags } =
            node!
          const thumbnail = featureImage![0]!
          const tagValues = tags!.map((tag) => tag!.title!)

          return (
            <ArticleCard
              key={idx}
              title={title!}
              slug={slug!}
              description={description!}
              publishedAt={publishedAt!}
              tags={tagValues}
              thumbnail={{
                url: thumbnail.secure_url!,
                width: thumbnail.width!,
                height: thumbnail.height!,
              }}
            />
          )
        })}
      </div>
    </Layout>
  )
}

const style = {
  heading: css`
    ${PAGE_HEADING_STYLE}
  `,
  wrapper: css`
    ${ARTICLE_STYLE}
  `,
}

interface IHeadProps extends HeadProps {
  data: ContentfulTagArticleTemplateQuery
}

export const Head: React.FC<IHeadProps> = ({ data }) => {
  const { title } = data.contentfulTag!

  return <SEO title={getTagTitle(title!)} />
}

export default ContentfulTagArticle
